import { Component } from 'react';
import {
  Grid,
  Select,
  InputLabel,
  FormControl,
  Radio,
  Typography,
  MuiThemeProvider,
  Input,
} from '@material-ui/core';
import request from 'superagent';
import { isEmpty, map, orderBy, deburr, lowerCase, split } from 'lodash';

import config from '../config';
import { muiTheme } from '../utils/tools';
import Loader from '../utils/Loader';

const actu = 'actu';
const event = 'event';
const marginBottomInterForm = 35;

class Configurator extends Component {
  state = {
    fetched: false,
    cities: {},
    widget: event,
    selectedID: '',
    containerSize: 'medium',
    zoom: 1,
    backgroundColor: '#ffffff',
    itemPerPage: 10,
    filterDate: 1,
    imageMaxHeight: 0,
  };

  componentDidMount() {
    this.fetchCities();
  }

  fetchCities() {
    request
      .get(config.apiRoot + config.apiCities)
      .then(res => res.body)
      .then(cities => {
        this.setState({
          cities: orderBy(cities, item => lowerCase(deburr(item.name)), [
            'asc',
          ]),
          fetched: true,
        });
      })
      .catch(err => {
        this.setState({ fetched: true });
        console.log('error during call : ' + err);
      });
  }

  handleCityChange = eventTriggered => {
    this.setState({ selectedID: eventTriggered.target.value });
  };

  handleContainerSizeChange = eventTriggered => {
    this.setState({ containerSize: eventTriggered.target.value });
  };

  handleWidgetTypeChange = eventTriggered => {
    this.setState({ widget: eventTriggered.target.value });
  };

  handleZoomChange = eventTriggered => {
    this.setState({ zoom: eventTriggered.target.value });
  };

  handleColorChange = eventTriggered => {
    this.setState({ backgroundColor: eventTriggered.target.value });
  };

  handlePaginationChange = eventTriggered => {
    this.setState({ itemPerPage: eventTriggered.target.value });
  };

  handleDateChange = eventTriggered => {
    this.setState({ filterDate: eventTriggered.target.value });
  };

  handleImageHeightChange = eventTriggered => {
    this.setState({ imageMaxHeight: eventTriggered.target.value });
  };

  render() {
    const {
      cities,
      selectedID,
      containerSize,
      widget,
      zoom,
      backgroundColor,
      itemPerPage,
      filterDate,
      imageMaxHeight,
      fetched,
    } = this.state;

    if (!fetched) {
      return (
        <Grid
          container
          direction="column"
          justify="center"
          alignItems="center"
          style={{ height: '100vh' }}
        >
          <Loader />
          {"Chargement du configurateur"}
        </Grid>
      );
    }

    if (isEmpty(cities)) {
      return null;
    }

    const displayScript = selectedID && containerSize && widget;

    return (
      <MuiThemeProvider theme={muiTheme}>
        <Grid container>
          <Grid item xs={12} sm={12} md={6}>
            <Grid
              container
              spacing={0}
              style={{ paddingLeft: 30, paddingRight: 30 }}
              direction="column"
            >
              <h1>Widgets IntraMuros: configurateur</h1>
              <Grid item>
                <Typography>
                  {
                    "Ce configurateur vous permet de paramétrer le widget avant de l'intégrer sur votre site."
                  }
                </Typography>
                <Typography>
                  {
                    'Renseignez tous les champs ci-dessous pour générer le code HTML que vous pourrez copier/coller sur votre page web pour intégrer le widget.'
                  }
                </Typography>
              </Grid>
              <Grid
                item
                style={{ marginTop: 30, marginBottom: marginBottomInterForm }}
              >
                {'Agenda'}
                <Radio
                  checked={widget === event}
                  onChange={this.handleWidgetTypeChange}
                  value={event}
                  name="radio-button-demo"
                  aria-label="A"
                />
                {'Actualités'}
                <Radio
                  checked={widget === actu}
                  onChange={this.handleWidgetTypeChange}
                  value={actu}
                  name="radio-button-demo"
                  aria-label="B"
                />
                <p style={{ margin: 0, color: '#565656' }}>
                  <span role="img" aria-label="infos">
                    ℹ️
                  </span>
                  {' Sélectionnez le type de widget'}
                </p>
              </Grid>

              <Grid item>
                <Grid direction="column">
                  <Grid>
                    <FormControl
                      style={{
                        minWidth: 200,
                        marginBottom: marginBottomInterForm,
                      }}
                    >
                      <InputLabel id="city-select-label">Commune</InputLabel>
                      <Select
                        native
                        id="city-select"
                        value={selectedID}
                        onChange={this.handleCityChange}
                        label="Commune"
                        style={{ marginBottom: 5 }}
                      >
                        <option value="" />
                        {map(cities, city => {
                          return (
                            <option value={city.id + '/' + city.agglo_id}>
                              {city.name} ({city.departmentNumber})
                            </option>
                          );
                        })}
                      </Select>
                      <p style={{ margin: 0, color: '#565656' }}>
                        <span role="img" aria-label="infos">
                          ℹ️
                        </span>
                        {' Sélectionnez votre commune'}
                      </p>
                    </FormControl>
                  </Grid>
                  <Grid>
                    <FormControl
                      style={{
                        minWidth: 200,
                        marginBottom: marginBottomInterForm,
                      }}
                    >
                      <InputLabel id="containerSize-label">
                        Largeur du conteneur
                      </InputLabel>
                      <Select
                        native
                        id="containerSize"
                        value={containerSize}
                        onChange={this.handleContainerSizeChange}
                        label="Conteneur"
                        style={{ marginBottom: 5 }}
                      >
                        <option value={'small'}>{'Small'}</option>
                        <option value={'medium'}>{'Medium'}</option>
                        <option value={'large'}>{'Large'}</option>
                      </Select>
                      <p style={{ margin: 0, color: '#565656' }}>
                        <span role="img" aria-label="infos">
                          ℹ️
                        </span>

                        {
                          ' Indiquez la largeur de la zone dans laquelle sera intégré le widget.'
                        }

                        <br />
                        <i>
                          {
                            "Small: Pour forcer l'affichage sur une seule colonne. Convient lorsque le widget est intégré dans une zone étroite."
                          }
                        </i>
                        <br />
                        <i>{"Medium: c'est la valeur par défaut."}</i>
                        <br />
                        <i>
                          {
                            'Large: Pour afficher le widget sur 3 colonnes. Convient lorsque le widget est intégré sur une pleine page.'
                          }
                        </i>
                      </p>
                    </FormControl>
                  </Grid>
                  <Grid>
                    <FormControl
                      style={{
                        minWidth: 200,
                        marginBottom: marginBottomInterForm,
                      }}
                    >
                      <InputLabel id="zoom-label">Affichage</InputLabel>
                      <Select
                        native
                        id="zoom"
                        value={zoom}
                        onChange={this.handleZoomChange}
                        label="Zoom"
                        style={{ marginBottom: 5 }}
                      >
                        <option value={'0.5'}>{'50%'}</option>
                        <option value={'0.8'}>{'80%'}</option>
                        <option value={'1'}>{'100%'}</option>
                        <option value={'1.2'}>{'120%'}</option>
                        <option value={'1.5'}>{'150%'}</option>
                      </Select>
                      <p style={{ margin: 0, color: '#565656' }}>
                        <span role="img" aria-label="infos">
                          ℹ️
                        </span>
                        {' Vous pouvez agrandir ou réduire la taille du texte.'}
                      </p>
                    </FormControl>
                  </Grid>
                  <Grid>
                    <FormControl
                      style={{
                        minWidth: 200,
                        marginBottom: marginBottomInterForm,
                      }}
                    >
                      <InputLabel id="pagination-label">Pagination</InputLabel>
                      <Select
                        native
                        id="pagination"
                        value={itemPerPage}
                        onChange={this.handlePaginationChange}
                        label="Pagination"
                        style={{ marginBottom: 5 }}
                      >
                        <option value={1}>{1}</option>
                        <option value={5}>{5}</option>
                        <option value={10}>{10}</option>
                        <option value={20}>{25}</option>
                        <option value={50}>{50}</option>
                      </Select>
                      <p style={{ margin: 0, color: '#565656' }}>
                        <span role="img" aria-label="infos">
                          ℹ️
                        </span>
                        {" Nombre d'élément pas page."}
                      </p>
                    </FormControl>
                  </Grid>
                  <Grid>
                    <FormControl
                      style={{
                        minWidth: 200,
                        marginBottom: marginBottomInterForm,
                      }}
                    >
                      <InputLabel id="imageMaxHeight-label">
                        {"Hauteur max de l'image"}
                      </InputLabel>
                      <Select
                        native
                        id="imageMaxHeight"
                        value={imageMaxHeight}
                        onChange={this.handleImageHeightChange}
                        label="Hauteur image"
                        style={{ marginBottom: 5 }}
                      >
                        <option value={0}>{'Automatique'}</option>
                        <option value={50}>{'50px'}</option>
                        <option value={100}>{'100px'}</option>
                        <option value={150}>{'150px'}</option>
                        <option value={200}>{'200px'}</option>
                        <option value={250}>{'250px'}</option>
                        <option value={300}>{'300px'}</option>
                      </Select>
                      <p style={{ margin: 0, color: '#565656' }}>
                        <span role="img" aria-label="infos">
                          ℹ️
                        </span>

                        {
                          ' Permet de limiter la hauteur maximale de l’image. Il est conseillé de laisser en automatique'
                        }
                      </p>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl
                      style={{
                        minWidth: 100,
                        marginBottom: marginBottomInterForm,
                      }}
                    >
                      <Input
                        type="color"
                        value={backgroundColor}
                        onChange={this.handleColorChange}
                      />
                      <p style={{ margin: 0, color: '#565656' }}>
                        <span role="img" aria-label="infos">
                          ℹ️
                        </span>
                        {" Couleur d'arrière plan."}
                      </p>
                    </FormControl>
                  </Grid>

                  {widget === event ? (
                    <Grid>
                      <FormControl
                        style={{
                          minWidth: 200,
                          marginBottom: marginBottomInterForm,
                        }}
                      >
                        <InputLabel id="date-label">Filtre de date</InputLabel>
                        <Select
                          native
                          id="date"
                          value={filterDate}
                          onChange={this.handleDateChange}
                          label="Date"
                          style={{ marginBottom: 5 }}
                        >
                          <option value={1}>{'Oui'}</option>
                          <option value={0}>{'Non'}</option>
                        </Select>
                        <p style={{ margin: 0, color: '#565656' }}>
                          <span role="img" aria-label="infos">
                            ℹ️
                          </span>
                          {
                            ' Affichera un bouton permettant de filtrer les événements par date'
                          }
                        </p>
                      </FormControl>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Grid
              container
              direction="column"
              justify="flex-start"
              alignItems="center"
              style={{ marginTop: 50 }}
            >
              {displayScript && widget === event ? (
                <Grid item>
                  <Typography style={{ marginBottom: 5 }}>
                    {
                      'Voici le code HTML à copier/coller sur votre site pour afficher le widget Agenda:'
                    }
                  </Typography>
                  <Typography style={{ borderStyle: 'solid', padding: 5 }}>
                    &lt;link
                    href=&quot;https://fonts.googleapis.com/css?family=Lato:300,400,700,700i,900&quot;
                    rel=&quot;stylesheet&quot;&gt;
                    <br />
                    &lt;script type=&quot;text/javascript&quot;
                    charset=&quot;UTF-8&quot;
                    src=&quot;https://widget.intramuros.org/js/intramuros-widget.1.2.0.js&quot;
                    async&gt;&lt;/script&gt;
                    <br />
                    &lt;script&gt;
                    <br />
                    window.intramurosCityId = {split(selectedID, '/')[0]};<br />
                    window.intramurosAggloId = {split(selectedID, '/')[1]};
                    <br />
                    window.zoom = {zoom};
                    <br />
                    window.containerSize = &apos;{containerSize}&apos;;
                    <br />
                    window.dateFilter = {filterDate};
                    <br />
                    window.itemPerPage = {itemPerPage};
                    <br />
                    window.imageMaxHeight = {imageMaxHeight};
                    <br />
                    window.backgroundColor = &apos;{backgroundColor}&apos;;
                    <br />
                    &lt;/script&gt;
                    <br />
                    &lt;div id=&quot;intramuros_events&quot;&gt;&lt;/div&gt;
                    <br />
                  </Typography>
                </Grid>
              ) : null}
              {displayScript && widget === actu ? (
                <Grid item>
                  <Typography style={{ marginBottom: 5 }}>
                    {
                      'Voici le code HTML à copier/coller sur votre site pour afficher le widget Actualités:'
                    }
                  </Typography>
                  <Typography style={{ borderStyle: 'solid', padding: 5 }}>
                    &lt;link
                    href=&quot;https://fonts.googleapis.com/css?family=Lato:300,400,700,700i,900&quot;
                    rel=&quot;stylesheet&quot;&gt;
                    <br />
                    &lt;script type=&quot;text/javascript&quot;
                    charset=&quot;UTF-8&quot;
                    src=&quot;https://widget.intramuros.org/js/intramuros-widget.1.2.0.js&quot;
                    async&gt;&lt;/script&gt;
                    <br />
                    &lt;script&gt;
                    <br />
                    window.intramurosCityId = {split(selectedID, '/')[0]};<br />
                    window.intramurosAggloId = {split(selectedID, '/')[1]};
                    <br />
                    window.zoom = {zoom};
                    <br />
                    window.containerSize = &apos;{containerSize}&apos;;
                    <br />
                    window.itemPerPage = {itemPerPage};
                    <br />
                    window.imageMaxHeight = {imageMaxHeight};
                    <br />
                    window.backgroundColor = &apos;{backgroundColor}&apos;;
                    <br />
                    &lt;/script&gt;
                    <br />
                    &lt;div id=&quot;intramuros_news&quot;&gt;&lt;/div&gt;
                    <br />
                  </Typography>
                </Grid>
              ) : null}
              {displayScript ? null : (
                <p style={{ color: '#e74747' }}>
                  Veuillez renseigner tous les champs pour générer le code HTML
                  du widget.
                </p>
              )}
            </Grid>
          </Grid>
        </Grid>
      </MuiThemeProvider>
    );
  }
}

export default Configurator;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MuiThemeProvider, Grid } from '@material-ui/core';
import { orderBy, size, slice } from 'lodash';
import { hot } from 'react-hot-loader/root';
import request from 'superagent';
import ReactPaginate from 'react-paginate';

import { muiTheme } from './utils/tools';
import Loader from './utils/Loader';
import NewsCard from './components/NewsCard';
import config from './config';

const NUMBER_OF_ITEMS_PER_PAGE =
  parseInt(window.itemPerPage, 10) > 0 ? parseInt(window.itemPerPage, 10) : 10;

class News extends Component {
  constructor(props) {
    super(props);
    this.state = { currentPage: 0 };
  }

  componentDidMount() {
    const { cityId, aggloId, cityINSEE, aggloSIREN } = this.props;
    if (cityId || aggloId) {
      this.fetchNews(cityId, aggloId);
    } else if (cityINSEE || aggloSIREN) {
      this.fetchNewsByCode(cityINSEE, aggloSIREN);
    }
  }

  fetchNews(cityId, aggloId) {
    request
      .get(config.apiRoot + config.apiNews)
      .query({ 'city-id': cityId })
      .query({ 'agglo-id': aggloId })
      .then(res => res.body)
      .then(news => {
        this.setState({
          news: orderBy(news, ['updated_at'], ['desc']),
          fetched: true,
        });
      })
      .catch(err => {
        this.setState({ error: true });
        console.log('error during call : ' + err);
      });
  }

  fetchNewsByCode(cityINSEE, aggloSIREN) {
    request
      .get(config.apiRoot + config.apiNews)
      .query({ 'city-insee': cityINSEE })
      .query({ 'agglo-siren': aggloSIREN })
      .then(res => res.body)
      .then(news => {
        this.setState({
          news: orderBy(news, ['updated_at'], ['desc']),
          fetched: true,
        });
      })
      .catch(err => {
        this.setState({ error: true });
        console.log('error during call : ' + err);
      });
  }

  handlePageClick = data => {
    console.log(data);
    this.setState({ currentPage: data.selected });
    document.activeElement.blur();

    if (window.scrollToTop) {
      const elmnt = document.getElementById('intramuros_news');
      if (elmnt) {
        elmnt.scrollIntoView(true);
      } else {
        window.scrollTo(0, 0);
      }
    }
  };

  render() {
    const { error, fetched, currentPage } = this.state;
    let { news } = this.state;

    if (!news) {
      if (error || fetched) {
        return null;
      }
      return <Loader />;
    }

    let divStyle = { overflowY: 'auto', overflowX: 'hidden' };
    if (window.maxHeight > 0) {
      divStyle = { ...divStyle, maxHeight: parseInt(window.maxHeight, 10) };
    }
    if (window.backgroundColor) {
      divStyle = { ...divStyle, backgroundColor: window.backgroundColor };
    }

    const numberOfNews = size(news);
    const numberOfPages = Math.ceil(numberOfNews / NUMBER_OF_ITEMS_PER_PAGE);
    console.log('number of pages = ' + numberOfPages);

    //  Calculate list of current page
    if (numberOfPages > 1) {
      news = slice(
        news,
        currentPage * NUMBER_OF_ITEMS_PER_PAGE,
        currentPage * NUMBER_OF_ITEMS_PER_PAGE + NUMBER_OF_ITEMS_PER_PAGE,
      );
    }

    return (
      <div style={divStyle}>
        <MuiThemeProvider theme={muiTheme}>
          {news.map(theNews => (
            <NewsCard news={theNews} key={theNews.id} />
          ))}
          {numberOfPages > 1 ? (
            <Grid container justify="center" style={{ marginTop: 30 }}>
              <ReactPaginate
                previousLabel="<"
                nextLabel=">"
                breakLabel="..."
                breakClassName="break-me"
                pageCount={numberOfPages}
                marginPagesDisplayed={1}
                pageRangeDisplayed={1} // saut de page: quand on clique sur les ...
                onPageChange={this.handlePageClick}
                containerClassName="pagination"
                subContainerClassName="pages pagination"
                activeClassName="active"
                forcePage={currentPage}
              />
            </Grid>
          ) : null}
        </MuiThemeProvider>
      </div>
    );
  }
}

News.propTypes = {
  cityId: PropTypes.number,
  aggloId: PropTypes.number,
  cityINSEE: PropTypes.string,
  aggloSIREN: PropTypes.string,
};

export default hot(News);
